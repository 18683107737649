import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { pathUrl } from '../../configs';
import {
  FLOW_STATE,
  IProductShelf,
  IPromotionBanner,
  IGetActiveList,
} from '../../core/interfaces';
import { HttpClient } from '@angular/common/http';
import { JsInterfaceService } from '../../core/services/shared/js-interface.service';
import {
  FirebaseParam,
  DeeplinkBody,
  EventGATagging,
} from '../../core/interfaces/js-interface.interface';
import { LoanProductService } from '../../core/services/loan-product/loan-product.service';
import { HttpService } from '@web-di-ld/apps/http';
import {
  BANNER_POSITION,
  BANNER_TYPE,
  BE_ERROR_CODE,
  GA_EVENT_ACTION,
  LOAN_PRODUCT_TYPE,
  NAVIGATION_PATH,
} from '../../core/constant/base.enum';
import { CustomerService } from '../../core/services/loan-product/customer.service';
import { FlowService } from '../../core/services/shared/flow-service.service';
import { UiLoadingService } from 'libs/apps/http/src/lib/ui-loading.service';
import { BannersService } from '../../core/services/banners/banners.service';
import { NavigationService } from '../../core/services/shared/navigation.service';
import { EMPTY, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NEW_LOANS, TOPUP_LOANS } from 'apps/di-ld/src/app/configs/app.config';
import { AppStateService } from '../../app-state.service';
import { DialogErrorHandlerService } from '../../shared/error-handler/dialog-error-handler.service';
import { DialogErrorHandlerService as DialogErrorHandlerProductShelfService } from '../loan-product-shelf/dialog-error-handler/dialog-error-handler.service';
import { ToastComponent } from '../../shared/components/ui/toast/toast.component';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';
import { ConsentService } from '../../core/services';

declare const window: any;

@Component({
  selector: 'web-di-ld-loan-product-shelf',
  templateUrl: './loan-product-shelf.component.html',
  styleUrls: ['./loan-product-shelf.component.scss'],
})
export class LoanProductShelfComponent implements OnInit, OnDestroy {
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
  public loanProductList!: Array<IProductShelf>;
  public topupProductList!: Array<IProductShelf>;
  public topPromotionBanners!: Array<IPromotionBanner>;
  public bottomPromotionBanners!: Array<IPromotionBanner>;
  public autoSwipeTime!: number;
  public topSmartPromo!: Array<IPromotionBanner>;
  public bottomSmartPromo!: Array<IPromotionBanner>;
  public autoSwipeTimePromo!: number;
  public subscription = new Subscription();
  public BE_ERROR_CODE = BE_ERROR_CODE;
  public activeListData!: IGetActiveList;
  public isShowCheckAppCard?: boolean;
  public isShowToast = false;

  public isLoading = false;
  private consent?: string;
  private haveSeenConsent?: boolean;

  constructor(
    private translocoService: TranslocoService,
    public http: HttpClient,
    private jsInterfaceService: JsInterfaceService,
    public loanProductService: LoanProductService,
    private httpService: HttpService,
    private customerService: CustomerService,
    private flowService: FlowService,
    public uiLoadingService: UiLoadingService,
    private bannersService: BannersService,
    private navigationService: NavigationService,
    public consentService: ConsentService,
    private router: Router,
    private route: ActivatedRoute,
    private appState: AppStateService,
    private zone: NgZone,
    public dialogService: DialogErrorHandlerService,
    public dialogErrorHandlerProductShelf: DialogErrorHandlerProductShelfService
  ) {
    this.onReceiveNativeCommand();
  }

  onReceiveNativeCommand() {
    window['onReceiveNativeCommand'] = (commandName: any, payload: any) => {
      this.zone.run(() => {
        console.log('commandName: ' + commandName, 'value: ' + payload);

        if (commandName === 'emailConfirmationStatus') {
          if (payload == 'false') {
            this.dialogService.setError('', 'verifyEmail', 'message');
          } else {
            this.getVerifyToken();
          }
        } else if (commandName === 'verifyEmailResult') {
          if (payload == 'true') {
            // If verify email success just remember this state
            this.jsInterfaceService.setIsVerifyEmail(true);
            this.dialogService.clearError();
            // Show toast verify email success
            this.isShowToast = true;
            this.getVerifyToken();
          } else {
            this.jsInterfaceService.closeWebView();
          }
        } else if (commandName === 'getLoanAppIdCallback') {
          if (payload) {
            const loanId = payload.trim();
            if (loanId !== '') {
              console.log('getLoanAppIdCallback > Has loanId', loanId);
              // redirect to status tracking -> app detail
              if (!this.appState.isInitial) {
                this.router.navigateByUrl(pathUrl.appInprogress, {
                  state: { applicationRef: loanId },
                });
              }
            } else {
              console.log('getLoanAppIdCallback > loanId === ""');
              // open product shelf
            }
          }
        }
      });
    };
  }

  showToast() {
    this.toastComponent.showToast('success', 'ยืนยันอีเมลสำเร็จ', '');
  }

  public setActiveLang(lang: string) {
    this.translocoService.setActiveLang(lang);
  }

  public enterFullScreen(
    url?: string,
    title?: string,
    activeList?: IGetActiveList
  ) {
    this.jsInterfaceService.enterFullScreen(url, title, activeList);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.appState.setHeaderTitle('apply-inprogress');
    }, 0);
    const event = {
      action: GA_EVENT_ACTION.VIEW,
      category: 'DigitalLending_LoanWebProductShelf',
      label: '',
      value: '',
    };
    this.gaTagging(event);
    this.appState.clearState();
    this.flowService.setFlow(FLOW_STATE.SHELF, FLOW_STATE.SHELF);
    this.route.queryParams.subscribe((params) => {
      const productType = params['productType'];
      if (!productType) {
        this.httpService.clearProductType();
        this.loanProductService.clearProductDetail();
      }

      if (environment.mode === 'sit') {
        // for investigate case sit only
        if (this.httpService.haveTokenApi()) {
          this.getVerifyToken();
        } else {
          // Check if have been verify email success just skip verify email state
          if (!this.jsInterfaceService.isVerifyEmail) {
            // Check Email Confirmation
            this.jsInterfaceService.checkEmailConfirmation();
          } else {
            this.getVerifyToken();
          }
        }
      } else {
        // Check Email Confirmation
        if (!this.jsInterfaceService.isVerifyEmail) {
          this.jsInterfaceService.checkEmailConfirmation();
        } else {
          this.getVerifyToken();
        }

        if (environment.mode === 'local') {
          this.getVerifyToken();
          // this.getKtbxAisConsent();
        }
      }
    });
  }

  gotoVerifyEmail() {
    this.jsInterfaceService.openEmailVerification();
  }

  async getPaotangLoan() {
    const loanResponse = await this.loanProductService.getPaotangLoans();
    this.loanProductService.setLoanProduct(loanResponse);
    this.loanProductList = loanResponse.data ? loanResponse.data.products : [];
    this.topupProductList = loanResponse.data
      ? loanResponse.data.productsTopup?.filter(
          (pt: IProductShelf) => pt.isEligible
        )
      : [];
    await this.getParams();
  }

  async getLoans(isToken = true) {
    if (isToken) {
      const loanResponse = await this.loanProductService.getLoans();
      this.loanProductService.setLoanProduct(loanResponse);
      this.loanProductList = loanResponse.data
        ? loanResponse.data.products
        : [];
      this.topupProductList = loanResponse.data
        ? loanResponse.data.productsTopup?.filter(
            (pt: IProductShelf) => pt.isEligible
          )
        : [];
    } else {
      this.loanProductList = [];
      this.topupProductList = [];
    }
    await this.getParams(isToken);

    // this.getPromotionBanner(BANNER_TYPE.BANNER);
    // this.getPromotionBanner(BANNER_TYPE.PROMO);
  }

  async getParams(isToken = true) {
    // Show toast verify email success
    console.log('wait 100');
    await this.delay(100);
    if (this.isShowToast) {
      console.log('show toast');
      this.showToast();
    }
    // REDIRECT TO STATUS PAGE
    await this.route.queryParams.subscribe(async (params) => {
      console.log('start queryParams');
      const page = params['page'];
      if (page === 'status' && !this.appState.isInitial) {
        console.log(
          'loan product shelf => page === status and go to app Inprogress'
        );
        // this.appState.setInitial(true);
        if (isToken) {
          ('loan product shelf => page === status, have token and go to app Inprogress');
          this.appState.setHeaderTitle('');
          return this.router.navigateByUrl(pathUrl.appInprogress);
        }
      } else {
        console.log('Product Shelf => page !== status');
        console.log('🚀 Product Shelf > location.pathname:', location.pathname);
        if (
          location.pathname !== pathUrl.loanProductShelf &&
          location.pathname !== pathUrl.appInprogress
        ) {
          console.log('Product Shelf > path name != loanProductShelf');
          let productType = await this.httpService.productType$.toPromise();
          const applicationRef = await this.httpService.applicationRef$.toPromise();
          if (!isToken) {
            productType = undefined;
          }

          if (applicationRef === undefined) {
            console.log('Product Shelf > applicationRef === undefined');
            if (NEW_LOANS.includes(productType)) {
              this.appState.setHeaderTitle('');
              if (this.activeListData?.activeList != null) {
                const activeList = this.activeListData.activeList?.find(
                  (activeList) =>
                    activeList.productCode === productType &&
                    activeList.isEndFlow === false
                );

                if (activeList) {
                  console.log('Product Shelf > go to app inprogress');
                  this.onNavigateToAppInprogress(activeList.applicationRef);
                  return;
                }
              }
              console.log('Product Shelf > go to loan apply');

              if (productType === LOAN_PRODUCT_TYPE.SMARTMONEY) {
                const productSmartmoney = this.loanProductList.filter(
                  (product: IProductShelf) =>
                    product.productCode === LOAN_PRODUCT_TYPE.SMARTMONEY
                );
                this.loanProductService.updateLoanProduct(productSmartmoney[0]);
                return this.router.navigateByUrl(pathUrl.aisKtbTermAndCon);
              } else {
                return this.router.navigateByUrl(pathUrl.loanApply.apply);
              }
            } else if (TOPUP_LOANS.includes(productType)) {
              this.jsInterfaceService.enterFullScreen(pathUrl.loanApply.topup);
              this.appState.setHeaderTitle('');
              console.log('Product Shelf > go to loan apply - topup');
              return this.router.navigateByUrl(pathUrl.loanApply.topup);
            }
          } else {
            console.log('product shelf > go to app details');
            this.appState.setHeaderTitle('');
            // redirect to status tracking -> app detail
            if (!this.appState.isInitial) {
              return this.router.navigateByUrl(pathUrl.appInprogress, {
                state: { applicationRef: applicationRef },
              });
            }
          }
        }
      }
      this.appState.setHeaderTitle('');
      this.isLoading = false;
      console.log('Product Shelf > noting');
      return;
    });
  }

  async getVerifyToken() {
    this.isLoading = true;
    this.subscription.add(
      this.httpService
        .getVerifyToken()
        .pipe(this.uiLoadingService.showLoading())
        .subscribe({
          next: async (verifyToken) => {
            console.log('🚀 ~ firstly -> getVerifyToken:', verifyToken);
            const data = verifyToken?.data;
            const lang = data.language === 'th-TH' ? 'th' : 'en';
            this.setActiveLang(lang);

            // Check cif no from token
            const cifNo = this.appState.getHasCifNo();
            if (cifNo) {
              await this.getActiveList();
            }

            if (cifNo) {
              await this.getLoans();
            } else {
              await this.getPaotangLoan();
            }

            // call get loanRefId from BIB
            if (!this.appState.isInitial) {
              this.jsInterfaceService.getLoanAppId();
            }
          },
          error: async (err) => {
            await this.getLoans(false);
            if (err.error.code === BE_ERROR_CODE.NOT_CIF_ON_CBS) {
              console.log('not_cif_on_cbs done');
              this.dialogErrorHandlerProductShelf.setError(
                '',
                'not_cif_on_cbs',
                ''
              );
            } else {
              catchError((error) => {
                console.error(error);
                return EMPTY;
              });
            }
          },
        })
    );
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async getCustomerDetail() {
    const customerDetail = await this.customerService.getCustomerDetail();
    if (customerDetail)
      this.customerService.updateCustomerDetail(customerDetail);
  }

  async getPromotionBanner(type: BANNER_TYPE) {
    const bannersRes = await this.bannersService.getPromotionBanners(
      type,
      FLOW_STATE.SHELF
    );
    this.autoSwipeTime = bannersRes.autoSwipeTime;
    if (
      type === BANNER_TYPE.BANNER &&
      bannersRes &&
      bannersRes.content.length > 0
    ) {
      this.topPromotionBanners = bannersRes.content.filter(
        (banner: IPromotionBanner) => banner.position === 'TOP'
      );
      this.bottomPromotionBanners = bannersRes.content.filter(
        (banner: IPromotionBanner) => banner.position === 'BOTTOM'
      );
    }

    if (
      type === BANNER_TYPE.PROMO &&
      bannersRes &&
      bannersRes.content.length > 0
    ) {
      this.topSmartPromo = bannersRes.content.filter(
        (banner: IPromotionBanner) => banner.position === 'TOP'
      );
      this.bottomSmartPromo = bannersRes.content.filter(
        (banner: IPromotionBanner) => banner.position === 'BOTTOM'
      );
    }
  }

  public onNavigateToCalculation() {
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: 'DigitalLending_LoanWebProductShelf',
      label: 'DigitalLending_LoanWebProductShelf_LoanCalculation',
      value: '',
    };

    this.gaTagging(event);
    this.enterFullScreen(
      pathUrl.loanApply.calculation,
      undefined,
      this.activeListData
    );
  }

  public onNavigateToAppInprogress(applicationRef?: string) {
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: 'DigitalLending_LoanWebProductShelf',
      label: 'DigitalLending_LoanWebProductShelf_AppInprogress',
      value: '',
    };
    this.gaTagging(event);

    if (applicationRef) {
      console.log('Product Shelf > have applicationRef and go to app details');
      // REDIRECT TO APP DETAILS IF HAS OPEN PRODUCT
      // if (!this.appState.isInitial) {
      this.router.navigate([pathUrl.appInprogress], {
        state: { applicationRef: applicationRef },
      });
      // }
    } else {
      console.log(
        'Product Shelf > not have applicationRef and go to app details'
      );
      this.router.navigate([pathUrl.appInprogress]);
    }
  }

  public async onNavigateToProduct(
    product: IProductShelf,
    isWebView?: boolean
  ) {
    this.appState.setProductName(product.productName);
    if (this.activeListData?.activeList != null) {
      const activeList = this.activeListData.activeList?.find(
        (activeList) =>
          activeList.productCode === product.productCode &&
          activeList.isEndFlow === false
      );

      if (activeList) {
        this.onNavigateToAppInprogress(activeList.applicationRef);
        return;
      }
    }

    if (
      product &&
      product?.productCode !== LOAN_PRODUCT_TYPE.SMARTMONEY &&
      product?.productCode !== LOAN_PRODUCT_TYPE._5PLUSOD &&
      product?.productCode !== LOAN_PRODUCT_TYPE._5PLUSTL
    ) {
      const productData: DeeplinkBody = {
        productType: product.productCode,
        marketCode: product.productMarketCode,
      };
      const event = {
        action: GA_EVENT_ACTION.CLICK,
        category: 'DigitalLending_LoanWebProductShelf',
        label: 'DigitalLending_LoanWebProductShelf_LoanProduct',
        value: '',
      };

      const dimension = [
        {
          key: 'PRODUCT_TYPE',
          value: product.productCode,
        },
      ];
      this.gaTagging(event, dimension);
      this.jsInterfaceService.openDeepLink(productData);
    } else if (product && product.isEligible) {
      const event = {
        action: GA_EVENT_ACTION.CLICK,
        category: 'DigitalLending_LoanWebProductShelf',
        label: 'DigitalLending_LoanWebProductShelf_LoanProduct',
        value: '',
      };

      const dimension = [
        {
          key: 'PRODUCT_TYPE',
          value: product.productCode,
        },
      ];
      this.gaTagging(event, dimension);
      // Comment this for handle error on product highlight page
      // this.getCustomerDetail();
      // New requirement skip this if no CIF
      product.isWebview = isWebView;
      this.loanProductService.updateLoanProduct(product);
      this.flowService.setState(FLOW_STATE.SHELF);
      if (product.productCode === LOAN_PRODUCT_TYPE.SMARTMONEY) {
        this.enterFullScreen(pathUrl.aisKtbTermAndCon, product.productName);
        return;
      }
      this.enterFullScreen(pathUrl.loanApply.apply, product.productName);
    }
  }

  public onNavigateToTopupProduct(product: IProductShelf) {
    if (product && product?.productCode.startsWith('TU_')) {
      const event = {
        action: GA_EVENT_ACTION.CLICK,
        category: 'DigitalLending_LoanWebProductShelf',
        label: 'DigitalLending_LoanWebProductShelf_Topup',
        value: '',
      };

      const dimension = [
        {
          key: 'PRODUCT_TYPE',
          value: product.productCode,
        },
      ];
      this.gaTagging(event, dimension);
      this.loanProductService.updateLoanProduct(product);
      this.flowService.setState(FLOW_STATE.SHELF);
      this.enterFullScreen(pathUrl.loanApply.topup);
    }
  }

  public onCall() {
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: 'DigitalLending_LoanWebProductShelf',
      label: 'DigitalLending_LoanWebProductShelf_KrungthaiContactCenter',
      value: '',
    };

    this.gaTagging(event);
    window.open('tel:021111111');
  }

  public onClickPromotionBanner(item: IPromotionBanner) {
    item.bannerType = BANNER_TYPE.BANNER;
    this.onTrackingBanner(item);
    this.handleBannerNavigation(item);
  }

  public onClickSmartPromo(item: IPromotionBanner) {
    item.bannerType = BANNER_TYPE.PROMO;
    this.onTrackingBanner(item);
    this.handleBannerNavigation(item);
  }

  handleBannerNavigation(item: IPromotionBanner) {
    if (item.isCallTemplate) {
      this.bannersService.updatePromotionBanner(item);
      this.jsInterfaceService.enterFullScreen(pathUrl.promotion);
    } else {
      if (item.navigationPathType === NAVIGATION_PATH.SCREENWEB) {
        this.navigationService.goToScreenWeb(item.navigationPath);
      } else if (item.navigationPathType === NAVIGATION_PATH.DEEPLINK) {
        this.navigationService.goToDeepLink(item.navigationPath);
      } else if (item.navigationPathType === NAVIGATION_PATH.WEBVIEW_EXTERNAL) {
        this.navigationService.goToWebviewExternal(item.navigationPath);
      } else if (item.navigationPathType === NAVIGATION_PATH.WEBVIEW_INTERNAL) {
        this.navigationService.goToWebviewInternal(item.navigationPath);
      } else {
        this.navigationService.goToLink(item.navigationPath);
      }
    }
  }

  getLabelBanner(type?: string, position?: string) {
    if (type === BANNER_TYPE.BANNER && position === BANNER_POSITION.TOP) {
      return 'MktTopBanner';
    } else if (
      type === BANNER_TYPE.BANNER &&
      position === BANNER_POSITION.BOTTOM
    ) {
      return 'MktBottomBanner';
    } else if (type === BANNER_TYPE.PROMO && position === BANNER_POSITION.TOP) {
      return 'MktTopSquare';
    } else if (
      type === BANNER_TYPE.PROMO &&
      position === BANNER_POSITION.BOTTOM
    ) {
      return 'MktBottomSquare';
    } else {
      return;
    }
  }

  onTrackingBanner(item: IPromotionBanner) {
    const itemOrder = item.order ? String(item.order) : '';
    const elementLabel = this.getLabelBanner(item.bannerType, item.position);
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: 'DigitalLending_LoanWebProductShelf',
      label: `DigitalLending_LoanWebProductShelf_${elementLabel}`,
      value: '',
    };

    const dimension = [
      {
        key: 'CAMPAIGN_ID',
        value: item.campNo,
      },
      {
        key: 'ITEM_ORDER',
        value: itemOrder,
      },
      {
        key: 'POSITION',
        value: item.position,
      },
      {
        key: 'CAMPAIGN_TYPE',
        value: item.bannerType,
      },
    ];
    this.gaTagging(event, dimension);
  }

  gaTagging(event: EventGATagging, dimension?: FirebaseParam[]) {
    this.jsInterfaceService.gaTagging(event, dimension);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async getActiveList() {
    this.activeListData = {} as IGetActiveList;
    this.activeListData = await this.loanProductService.getActiveList();

    if (
      this.activeListData?.activeList != null &&
      this.activeListData?.activeList.length > 0
    ) {
      this.isShowCheckAppCard = true;
    }
  }

  closeDialog() {
    if (environment.mode === 'local') {
      this.dialogErrorHandlerProductShelf.displayErrorProductShelf = false;
    } else {
      console.log('closeDialog and closeWebView');
      this.jsInterfaceService.closeWebView();
    }
  }
}
