<div class="p-d-flex p-ai-center p-jc-center">
  <div class="information-message" id="divProductShelfInformationMessage">
    <span
      [innerHTML]="'loanProductShelf.informationMessage' | transloco"
    ></span>
    <span class="link" (click)="call()" id="divProductShelfContactNumber"
      >02-111-1111</span
    >
  </div>
</div>
