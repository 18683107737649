export const environment = {
  baseUrl: 'http://localhost:4200',
  dglAuthenApiBaseUrl: 'https://extgw-dgl-pt-uat.arisetech.dev/',
  dglApiBaseUrl: 'https://extgw-dgl-pt-uat.arisetech.dev/',
  production: false,
  mode: 'uat',
  version: '0.2.17',
  tagId: true,
  noCache: true,
};
